import Vue from "vue"
import Vuetify from "vuetify/lib/framework"

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        //primary: "#005b96",
        primary: "#4a1e83",
        secondary: "#424242",
        primaryAction: "#141414", // primary actions (icons at top of card)
        secondaryAction: "#545859", // secondary actions (icons within a list)
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
      dark: {
        primary: "#005b96",
        secondary: "#424242",
        primaryAction: "#141414", // primary actions (icons at top of card)
        secondaryAction: "#545859", // secondary actions (icons within a list)
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
      },
    },
  },
})
