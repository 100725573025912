<template>
  <icon-button :type="type" :to="to" :tooltip="tooltip" :disabled="disabled" :icon="icon" :color="color" @click="$emit('click')" />
</template>

<script>
import IconButton from "./IconButton.vue"
export default {
  components: { IconButton },
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    to: {
      type: String || Object,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: "primaryAction",
    },
  },
}
</script>
