<template>
  <span>
    <v-tooltip v-if="showTooltip" bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" :disabled="disabled" v-on="on" @click="$emit('click')">
          <v-icon :color="color">{{ icon }}</v-icon>
        </v-btn>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
    <v-btn v-if="!showTooltip" icon @click="$emit('click')" :disabled="disabled"
      ><v-icon :color="color">{{ icon }}</v-icon></v-btn
    >
  </span>
</template>

<script>
export default {
  model: {
    event: "click",
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    to: {
      type: String || Object,
      required: false,
    },
    tooltip: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    icon: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
    },
  },
  computed: {
    showTooltip() {
      return this.tooltip != null
    },
  },
}
</script>
