import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/auth"
import { RecaptchaVerifier } from "firebase/auth"
import store from "./store"
import * as userService from "./services/userService"
import VueCookies from "vue-cookies"
import * as filters from "./framework/filters"
import IdleVue from "idle-vue"

Vue.use(VueCookies)
Vue.config.productionTip = false
const firebaseProject = process.env.VUE_APP_FIREBASE_PROJECT
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: `${firebaseProject}.firebaseapp.com`,
  projectId: firebaseProject,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

let firebaseApp = firebase.initializeApp(firebaseConfig)

export const auth = firebase.auth()

let app = auth.onAuthStateChanged(async user => {
  let mhLawUser = null
  if (user != null) {
    try {
      mhLawUser = await userService.getUser(user.uid)
    } catch (error) {
      await store.dispatch("updateUser", null)
    }
  }
  await store.dispatch("updateUser", { mhLawUser, user })
})

export const getCurrentUser = async () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      unsubscribe()
      resolve(user)
    }, reject)
  })
}

const eventsHub = new Vue()
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1000 * 60 * 30,
})

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App),
}).$mount("#app")
