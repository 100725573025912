import { render, staticRenderFns } from "./LoadingOverlay.vue?vue&type=template&id=681413a4&"
import script from "./LoadingOverlay.vue?vue&type=script&lang=js&"
export * from "./LoadingOverlay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/mhl2_frontend/mhl2_frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('681413a4')) {
      api.createRecord('681413a4', component.options)
    } else {
      api.reload('681413a4', component.options)
    }
    module.hot.accept("./LoadingOverlay.vue?vue&type=template&id=681413a4&", function () {
      api.rerender('681413a4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/LoadingOverlay.vue"
export default component.exports