<template>
  <div class="d-flex">
    <v-combobox
      :menu-props="{ maxHeight: '700' }"
      class="pt-6"
      label="Quick Search"
      v-model="selected"
      item-text="display"
      :items="searchItems"
      :filter="searchFilter"
      dense
      outlined
      clearable
      @update:search-input="search"
      @change="searchItemSelected"
    >
      <template v-slot:prepend-item>
        <v-list-item> {{ searchItems.length }} search hits </v-list-item>
      </template>
      <template v-slot:item="{ item }">
        <div>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>{{ item.display }}</v-list-item-title>
              <v-list-item-subtitle v-html="item.marked_text"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </div>
      </template>
    </v-combobox>
  </div>
</template>

<style>
mark {
  background: #69aef5 !important;
  color: white;
}
</style>
<script>
import * as elasticSearchService from "../services/elasticSearchService.js"
import * as filterArgsStorage from "../store/filterArgsStorage.js"
import eventBus from "../framework/eventBus"

export default {
  components: {},
  data() {
    return {
      searchItems: [],
      selected: null,
      searchTimerId: null,
    }
  },
  methods: {
    searchFilter() {
      // Disables Filtering otherwise it will try and filter on just the display name
      return true
    },
    searchItemSelected() {
      if (this.selected != null) {
        const searchItem = this.selected
        if (searchItem.type == "User") {
          const filterArgs = { acitiveOnly: false, name: searchItem.first_name, email: searchItem.email, twoFaActiveOnly: false, showFilter: true }
          if (this.$router.currentRoute.path != "/users") {
            filterArgsStorage.addUpdateFilterArgs("Users", filterArgs)
            this.$router.push("/users")
          } else {
            eventBus.$emit("UserFilterChanged", filterArgs)
          }
        }
        if (searchItem.type == "Contact" || searchItem.type == "Client") {
          this.$router.push({ name: "contact-details", params: { contactKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "Matter") {
          this.$router.push({ name: "matter-details", params: { matterKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "ClientAccount") {
          this.$router.push({ name: "client-account-details", params: { clientAccountKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "TimeEntry") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_time_entries" } })
        }
        if (searchItem.type == "User") {
          this.$router.push({ name: "user-details", params: { uid: searchItem.uid } })
        }
        if (searchItem.type == "CreditMatterTransaction") {
          if (searchItem.credit_type == "Time Entry") {
            this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_time_entries" } })
          }
          if (searchItem.credit_type == "Disbursement") {
            this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_disbursements" } })
          }
          if (searchItem.credit_type == "Flat Fee") {
            this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_flat_fees" } })
          }
          if (searchItem.credit_type == "General Cheque") {
            this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_disbursements" } })
          }
        }
        if (searchItem.type == "Disbursement") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_disbursements" } })
        }
        if (searchItem.type == "GeneralCheque") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_disbursements" } })
        }
        if (searchItem.type == "FlatFee") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_flat_fees" } })
        }
        if (searchItem.type == "TrustCheque") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_trust_records" } })
        }
        if (searchItem.type == "TrustTransferRecord") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_trust_records" } })
        }
        if (searchItem.type == "ArTrustCheque") {
          this.$router.push({ name: "ar-trust-cheque-details", params: { chequeKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "TrustRecord") {
          this.$router.push({ name: "trust-receipt-details", params: { trustReceiptKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "Bill") {
          this.$router.push({ name: "bill-details", params: { billKey: searchItem.url_safe_key } })
        }
        if (searchItem.type == "Payment") {
          this.$router.push({ name: "matter-details-select-tab", params: { matterKey: searchItem.matter_url_safe_key, selectTab: "matter_billing" } })
        }
      }
    },
    async search(input) {
      if (this.selected != null && this.selected.display == input) {
        return
      }
      clearTimeout(this.searchTimerId)
      this.searchTimerId = setTimeout(async () => {
        if (input == null || input.length <= 3) {
          this.searchItems = []
          return
        } else {
          const result = await elasticSearchService.search(input)
          const items = []
          for (const i in result) {
            var marked_text = ""
            const document = result[i]
            for (const property in document) {
              const value = document[property]
              if (new RegExp(input, "gi").test(value)) {
                marked_text += `${property}: ${value} `
              }
            }
            document.marked_text = marked_text.replace(new RegExp(input, "gi"), str => `<mark>${str}</mark>`)
            items.push(document)
          }
          this.searchItems = items
        }
      }, 700)
    },
  },
}
</script>
