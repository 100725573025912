<template>
  <v-row>
  <v-dialog
    v-model="visible"
    persistent
    max-width="600px"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title class="primary white--text">
        <span class="text-h4">Roles and Permissions</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-tabs
            v-model="tab"
          >
          <v-tab class="ml-2" href="#userRoles">
            User Roles
          </v-tab>
          <v-tab class="ml-2" href="#userPermissions">
            User Permissions
          </v-tab>
          <v-tabs-items :value="tab">
            <v-tab-item value="userRoles">
            <div class="pt-2 pl-2 flex flex-row flex-wrap">
              <div v-for="userRole in userRoles" :key="userRole.id">
                {{ userRole.name }} ({{ userRole.permissions.length }}) <br>
              </div>
            </div>
            </v-tab-item>
            <v-tab-item value="userPermissions">
              <div class="pt-2 pl-2 flex flex-row flex-wrap">
                <v-data-table
                  :headers="headers()"
                  :items="userPermissions"
                  class="elevation-1"
                  dense
                  :items-per-page="10"
                  :sort-by="'permission'"
                  no-data-text="No Data Available"
                >
                  <template v-slot:item="{ item }">
                    <tr>
                      <td>{{ item }}</td>
                      <td :style="{'whiteSpace': 'pre-line'}">{{ permissionRoles(item) }}</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-tab-item>
          </v-tabs-items>
          </v-tabs>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <app-secondary-button @click="close"> Close </app-secondary-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </v-row>
</template>

<script>
import eventBus from '@/framework/eventBus'
import AppSecondaryButton from '../common/buttons/AppSecondaryButton.vue';

export default {
  components: {
    AppSecondaryButton,
  },
  data() {
    return {
      visible: false,
      userId: null,
      tab: null,
      userRoles: [],
      userPermissions: [],
    }
  },
  methods: {
    close() {
      this.visible = false
    },
    headers() {
      return [
        { text: "Permission", sortable: false, value: "permission" },
        { text: "Granted By", sortable: false, value: "granted_by" },
      ]
    },
    permissionRoles(permission) {
      var roles = [];
      for (var i = 0; i < this.userRoles.length; i++) {
        var role = this.userRoles[i];
        if (role.permissions.includes(permission)) {
          roles.push(role.name);
        }
      }

      // This is a template literal (backticks). This is required with the 'white-space: pre-line' style in the v-data-table
      // In order for new lines to be displayed. <br> and \n do not work since the text renders the raw text
      return roles.join(`
      `);
    }
  },

  created() {
    eventBus.$on(`${this.routeName}showUserRolesPermissionsDialog`, () => {
      this.visible = true
      var user = this.$store.getters.currentUser();
      
      this.userRoles = user.security_roles.sort((a, b) => a.name.localeCompare(b.name));
      this.userPermissions = user.user_permissions.sort((a, b) => a.localeCompare(b));
    })
  },
  beforeDestroy() {
    eventBus.$off(`${this.routeName}showUserRolesPermissionsDialog`)
  },
}

</script>