import Vue from "vue"
import { format } from "date-fns"
export const dateFormat = process.env.VUE_APP_DATE_FORMAT
export const dateTimeFormat = process.env.VUE_APP_DATETIME_FORMAT
export const project = process.env.VUE_APP_FIREBASE_PROJECT

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value
  }
  var formatter = new Intl.NumberFormat("en-CA", {
    style: "currency",
    currency: "CAD",
  })
  return formatter.format(value)
})

Vue.filter("toPercentage", function (value) {
  if (typeof value !== "number") {
    return value
  }

  return value + "%"
})

Vue.filter("toPercentageWithDecimal", function (value) {
  if (typeof value !== "number") {
    return value
  }

  return value.toFixed(2) + "%"
})

//use for converting big query timestamp to local datetime
Vue.filter("bigqueryTimestampToLocalDisplay", function (value) {
  if (!value) return ""

  var dateObject = new Date(value)

  if (project == "fidatapro-live") {
    dateObject = Date.UTC(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
      dateObject.getHours(),
      dateObject.getMinutes(),
      dateObject.getSeconds(),
    )
    return format(dateObject, dateTimeFormat)
  } else {
    if (dateObject.toDate) {
      // convert timestamps to javascript dates
      dateObject = dateObject.toDate()
    }
    return format(dateObject, dateTimeFormat)
  }
})

//datetime from datastore not include timezone
Vue.filter("utcDateTimeToLocalDisplay", function (value) {
  if (!value) return ""

  var dateObject = new Date(value)

  //set no timezone datetime to UTC
  dateObject = Date.UTC(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    dateObject.getHours(),
    dateObject.getMinutes(),
    dateObject.getSeconds(),
  )

  return format(dateObject, dateTimeFormat)
})

//date from datastore not include timezone
Vue.filter("utcDateTimeToLocalDateDisplay", function (value) {
  if (!value) return ""

  var dateObject = new Date(value)

  //set no timezone datetime to UTC
  dateObject = Date.UTC(
    dateObject.getFullYear(),
    dateObject.getMonth(),
    dateObject.getDate(),
    dateObject.getHours(),
    dateObject.getMinutes(),
    dateObject.getSeconds(),
  )

  return format(dateObject, dateFormat)
})

//datetime from big query include timezone
Vue.filter("bigqueryUtcDateTimeToLocalDisplay", function (value) {
  if (!value) return ""

  var dateObject = new Date(value)

  if (dateObject.toDate) {
    // convert timestamps to javascript dates
    dateObject = dateObject.toDate()
  }
  return format(dateObject, dateTimeFormat)
})

Vue.filter("toShortString", function (value) {
  if (typeof value !== "string") {
    return value
  }
  if (value.length <= 100) {
    return value
  } else {
    return value.substring(0, 100) + "..."
  }
})

Vue.filter("toMediumString", function (value) {
  if (typeof value !== "string") {
    return value
  }
  if (value.length <= 400) {
    return value
  } else {
    return value.substring(0, 400) + "..."
  }
})

//standard date format to customt formt. YYYY-MM-DD to MM-DD-YYYY
Vue.filter("toDateDisplay", function (value) {
  if (typeof value !== "string") {
    return value
  }
  let array = value.split("-")
  return `${array[1]}-${array[2]}-${array[0]}`
})
