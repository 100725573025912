<template>
  <app-button :color="color" :to="to" :type="type" :disabled="disabled" @click="$emit('click')">
    <slot />
  </app-button>
</template>

<script>
import AppButton from "./AppButton.vue"
export default {
  components: { AppButton },
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    color: {
      type: String,
      required: false,
      default: "primary",
    },
    to: {
      type: String || Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
