var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        { attrs: { dense: "", dark: "", color: "primary accent-3", app: "" } },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.drawer = !_vm.drawer
              },
            },
          }),
          _c("img", {
            attrs: {
              src: require("./../assets/full-logo.png"),
              height: _vm.appBarHeight,
            },
          }),
          _c("v-spacer"),
          _c("full-text-search"),
          _c("full-text-search-hint"),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { bottom: "", temporary: "", app: "" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-list-item",
            [
              _c(
                "v-list-item-content",
                [
                  _c("v-list-item-title", { staticClass: "text-h6" }, [
                    _vm._v(" FidataPro "),
                  ]),
                  _c("v-list-item-subtitle", [_vm._v(" version 1.0.45 ")]),
                  _c(
                    "v-list-item-subtitle",
                    [
                      _vm._v(_vm._s(_vm.userName) + " "),
                      _c("icon-button", {
                        staticClass: "m-0 p-0",
                        attrs: {
                          icon: "mdi-account-key",
                          tooltip: "View roles & permissions",
                        },
                        on: { click: _vm.showRolesPermissionsDialog },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-list",
            { attrs: { dense: "", nav: "" } },
            [
              _c("nav-menu-item", {
                attrs: { link: "/", title: "Home", icon: "mdi-home" },
              }),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item-title", [
                            _vm._v("Practice Management"),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(
                  _vm.filterNoAccessMenuItems(_vm.practiceManagementMenuItems),
                  function (item) {
                    return _c("nav-menu-item", {
                      key: item.title,
                      attrs: {
                        link: item.link,
                        title: item.title,
                        icon: item.icon,
                      },
                    })
                  }
                ),
                1
              ),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Accounting")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(
                  _vm.filterNoAccessMenuItems(_vm.accountingMenuItems),
                  function (item) {
                    return _c("nav-menu-item", {
                      key: item.title,
                      attrs: {
                        link: item.link,
                        title: item.title,
                        icon: item.icon,
                      },
                    })
                  }
                ),
                1
              ),
              _vm.canViewReport
                ? _c(
                    "v-list-group",
                    {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [_vm._v("Reports")]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        408054266
                      ),
                    },
                    _vm._l(
                      _vm.filterNoAccessMenuItems(_vm.reportMenuItems),
                      function (item) {
                        return _c("nav-menu-item", {
                          key: item.title,
                          attrs: {
                            link: item.link,
                            title: item.title,
                            icon: item.icon,
                          },
                        })
                      }
                    ),
                    1
                  )
                : _vm._e(),
              _c(
                "v-list-group",
                {
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item-title", [_vm._v("System Admin")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                _vm._l(
                  _vm.filterNoAccessMenuItems(_vm.systemAdminMenuItems),
                  function (item) {
                    return _c("nav-menu-item", {
                      key: item.title,
                      attrs: {
                        link: item.link,
                        title: item.title,
                        icon: item.icon,
                      },
                    })
                  }
                ),
                1
              ),
              _c("nav-menu-item", {
                attrs: {
                  link: "/sign_out",
                  title: "Sign Out",
                  icon: "mdi-account",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }