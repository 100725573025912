<template>
  <v-snackbar v-model="show" :timeout="timeout" :color="color">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="show = false"> Close </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import eventBus from "../framework/eventBus"

export default {
  name: "messaging",
  data() {
    return {
      show: false,
      message: "",
      timeout: 2000,
      error: false,
      notificationColor: "primary",
    }
  },
  computed: {
    color() {
      return this.notificationColor
    },
  },
  created() {
    eventBus.$on("show-message", message => {
      this.message = message
      this.error = false
      this.show = true
      this.notificationColor = "primary"
    })
    eventBus.$on("show-error", message => {
      this.message = message
      this.error = true
      this.show = true
      this.notificationColor = "red"
    })
    eventBus.$on("show-warning", message => {
      this.message = message
      this.error = false
      this.show = true
      this.notificationColor = "#8B8000"
    })
  },
}
</script>
