import Vue from "vue"
import * as logger from "../framework/logger"
import * as appNotification from "../framework/appNotification.js"

const eventBus = new Vue()

eventBus.$on("exception", exception => {
  let message = "An unknown exception occurred"

  if (exception.response && exception.response.data && exception.response.data.message) {
    message = exception.response.data.message
  } else if (exception.message) {
    message = exception.message
  }

  logger.error(exception)
  appNotification.showErrorMessage(message, exception)
})
eventBus.$on("nullResponse", exception => {
  logger.error(exception)
  appNotification.showErrorMessage(exception)
})
export default eventBus
