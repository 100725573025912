<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ attrs, on }">
        <v-btn icon v-bind="attrs" v-on="on" @click="$emit('click')">
          <v-icon color="white"> mdi-help-circle-outline </v-icon>
        </v-btn>
      </template>
      <v-card min-width="300">
        <v-list>
          <v-list-item-title>
            <div class="px-5" v-html="messages"></div>
          </v-list-item-title>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item-title class="px-5">
            <small> Syntax Document:</small>
            <primary-icon-button icon="mdi-file-pdf-box" dense @click="openSyntaxDocument" tooltip="Open Syntax Document" />
          </v-list-item-title>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import PrimaryIconButton from ".//common/buttons/PrimaryIconButton.vue"
export default {
  components: {
    PrimaryIconButton,
  },
  data() {
    return {
      messages:
        'Quick Search Examples<br />1. Double quotes support search for phrases. e.g. "Brandon, Manitoba"<br /> 2. "AND" or "OR" support multiple keywords. e.g. "Brandon, Manitoba" AND 60003  <br /> 3. Add "~" at end of a word to support fuzzy. e.g. Brando~ <br />',
    }
  },
  methods: {
    openSyntaxDocument() {
      window.open("https://drive.google.com/file/d/1Prlg_2A3tG-bC-l-nCqpbjgvR3ZCN79v/view?usp=sharing", "_blank")
    },
  },
}
</script>
