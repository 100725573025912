<template>
  <v-row justify="center">
    <v-dialog v-model="visible" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Reset Password</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" @submit.prevent="resetPassword" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <app-text-box label="Email" v-model="email" required :rules="emailRules" />
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <app-secondary-button @click="close"> Close </app-secondary-button>
          <app-primary-button @click="resetPassword"> Send Reset Email </app-primary-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import eventBus from "../../framework/eventBus"
import * as appNotification from "../../framework/appNotification"
import { auth } from "../../main.js"
import AppPrimaryButton from "../../components/common/buttons/AppPrimaryButton.vue"
import AppSecondaryButton from "../../components/common/buttons/AppSecondaryButton.vue"
import AppTextBox from "../../components/common/form_fields/AppTextBox.vue"

export default {
  components: { AppPrimaryButton, AppSecondaryButton, AppTextBox },
  props: {
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      valid: false,
      visible: false,
      email: null,
      emailRules: [v => !!v || "Email required", v => /.+@.+\..+/.test(v) || "Email must be valid"],
    }
  },
  methods: {
    async close() {
      this.visible = false
      this.email = null
      this.$refs.form.resetValidation()
    },
    resetPassword() {
      try {
        let valid = this.$refs.form.validate()
        if (!valid) return
        auth.sendPasswordResetEmail(this.email)
        appNotification.showInfoMessage(`Password reset email sent to ${this.email}`)
        this.close()
      } catch (exception) {
        appNotification.showErrorMessage("Error sending password reset email.")
      }
    },
  },
  created() {
    // didn't destroy this eventbus since back button from browser will revisit the same route, destroy the eventbus will kill new created eventbus with same name.
    eventBus.$on(`${this.routeName}showResetPasswordDialog`, () => {
      this.visible = true
    })
  },
}
</script>
