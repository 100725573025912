<template>
  <v-text-field
    v-model="model"
    :label="fullLabel"
    :rules="rules"
    :readonly="readonly"
    :disabled="disabled"
    :required="required"
    :type="type"
    :counter="counter"
    :autofocus="autofocus"
    :filled="filled"
    :autocomplete="autocomplete"
    :dense="dense"
    :prefix="prefix"
    :suffix="suffix"
    hide-spin-buttons
    @change="$emit('change')"
    @keyup.enter="$emit('enter')"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    rules: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    counter: {
      type: Number,
      required: false,
    },
    type: {
      type: String,
      required: false,
    },
    autocomplete: {
      type: String,
      required: false,
    },
    filled: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    prefix: {
      type: String,
      required: false,
    },
    suffix: {
      type: String,
      required: false,
    },
    autofocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    fullLabel() {
      return this.required ? `${this.label} *` : this.label
    },
    model: {
      get() {
        return this.value
      },
      set(value) {
        return this.$emit("input", value)
      },
    },
  },
}
</script>
