<template>
  <v-btn text :to="to" :class="color" :type="type" :disabled="disabled" @click="$emit('click')">
    <slot />
  </v-btn>
</template>

<style scoped>
.v-btn:disabled {
  background: #aeafac !important;
}
</style>

<script>
export default {
  model: {
    event: "click",
  },
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    to: {
      type: String || Object,
      required: false,
    },
    color: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
