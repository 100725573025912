<template>
  <v-app>
    <v-main>
      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.link">
            {{ item.title.toUpperCase().split("-").join(" ") }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <Navigation v-if="isLoggedIn" />
      <router-view :key="$route.fullPath" />
      <messaging />
      <loading-overlay />
      <view-user-roles-permissions-dialog :routeName="$route.fullPath" />
    </v-main>
  </v-app>
</template>

<style>
.clickable {
  cursor: pointer;
}

.selectedRow {
  background-color: gainsboro;
}

.filter-divider {
  padding-bottom: 10px;
}

.page-title {
  padding-inline: 30px;
  font-size: xx-large;
  font-weight: bold;
}

.smallest-width {
  min-width: 100px;
}

.small-width {
  min-width: 150px;
}

.medium-width {
  min-width: 200px;
}

.large-width {
  min-width: 250px;
}

#tableHeader > th {
  background: rgb(230, 228, 233);
  color: var(--v-secondary-base);
  font-size: medium;
}
.v-expansion-panels > .v-expansion-panel {
  color: rgb(0, 0, 0, 0.6) !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  background: rgb(230, 228, 233) !important;
  color: var(--v-secondary-base) !important;
  font-size: small !important;
}
.v-data-table-header th {
  white-space: nowrap;
}

.v-data-table-header th {
  white-space: nowrap;
}
.v-data-footer {
  align-content: center;
  max-height: 38px;
}

.pull-left {
  position: absolute;
  left: 0px;
}
</style>

<script>
import Navigation from "./components/Navigation.vue"
import Messaging from "./components/Messaging.vue"
import LoadingOverlay from "./components/LoadingOverlay.vue"
import * as breadcrumbsStorage from "./store/breadcrumbsStorage.js"
import eventBus from "./framework/eventBus"
import firebase from "firebase/compat/app"
import "firebase/compat/database"
import * as appNotification from "./framework/appNotification.js"
import ViewUserRolesPermissionsDialog from "./components/users/ViewUserRolesPermissionsDialog.vue"

export default {
  name: "App",
  components: {
    Navigation,
    Messaging,
    LoadingOverlay,
    ViewUserRolesPermissionsDialog,
  },
  data() {
    return {
      absolute: true,
      overlay: false,
      items: [],
      text: "Recently Viewed:",
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.currentUser != null
    },
  },
  async onIdle() {
    if (this.$store.state.currentUser != null) {
      appNotification.showErrorMessage("Session Expired. Please log in again")
      await firebase.auth().signOut()
      await this.$store.dispatch("updateUser", null)
      this.$router.push("/login")
    }
  },
  created() {
    this.items = breadcrumbsStorage.getBreadcrumbs()
    eventBus.$on("breadUpdated", () => {
      this.items = breadcrumbsStorage.getBreadcrumbs()
    })

    const myNodeRef = firebase.database().ref("events/MH_LAW")
    //Listen for child added event
    myNodeRef.on("child_added", snapshot => {
      let eventDto = snapshot.val()
      let eventType = eventDto.event
      if (eventType == "topic_disbursement") {
        eventBus.$emit("disbursementsDisbursementUpdated", JSON.parse(eventDto.dto))
      } else if (eventType == "topic_disbursement_transaction") {
        eventBus.$emit("matter-detailsDisbursementTransactionUpdated", JSON.parse(eventDto.dto))
      }
    })
  },
}
</script>
