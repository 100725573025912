<template>
  <v-btn text :small="small" :to="to" :color="color" :type="type" @click="$emit('click')">
    <slot />
  </v-btn>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: "button",
    },
    color: {
      type: String,
      required: false,
      default: "secondary",
    },
    to: {
      type: Object,
      required: false,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>
