<template>
  <div>
    <v-overlay :absolute="absolute" :value="overlay" style="z-index: 999">
      <div class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import eventBus from "../framework/eventBus"

export default {
  data() {
    return {
      absolute: true,
      overlay: false,
      loadingCount: 0,
    }
  },
  created() {
    eventBus.$on("loading-start", () => {
      this.loadingCount += 1
      this.overlay = true
    })
    eventBus.$on("loading-stop", () => {
      this.loadingCount -= 1
      if (this.loadingCount == 0) this.overlay = false
    })
  },
}
</script>
