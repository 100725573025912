<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8">
        <v-card class="elevation-12 rounded-lg">
          <v-row>
            <v-col cols="12" md="4" class="primary accent-3 rounded-lg rounded-r-0">
              <v-card-text class="justify-center mt-12 white--text text-h5">Welcome to FidataPro</v-card-text>
            </v-col>
            <v-col cols="12" md="8">
              <v-card-title>Sign In</v-card-title>
              <v-form ref="form" v-model="valid" @submit.prevent="login" lazy-validation>
                <v-card-text>
                  <app-text-box autocomplete="username" v-model="email" label="Email" required :rules="emailRules" filled @enter="login" />
                  <app-text-box autocomplete="current-password" v-model="password" label="Password" type="password" required :rules="passwordRules" filled @enter="login" />
                  <v-alert type="error" v-if="error" dense outlined>{{ error }}</v-alert>
                </v-card-text>
                <v-card-actions>
                  <app-primary-button @click="login" :disabled="loginInProgress"> Login </app-primary-button>
                  <app-secondary-button @click="forgotPassword" :small="true" :disabled="forgotPasswordDisabled">Forgot Password?</app-secondary-button>
                </v-card-actions>
                <div v-if="!verificationId">
                  <div id="recaptcha-container-id"></div>
                </div>
              </v-form>
              <v-form ref="form2" @submit.prevent="sendMfaCode" lazy-validation>
                <v-container v-if="verificationId">
                  <v-row class="d-flex align-center">
                    <v-col>
                      <app-text-box label="Enter 2FA Code" v-model="mfaCode" @keydown.enter="sendMfaCode" :rules="TwoFACodeRules"></app-text-box>
                    </v-col>
                    <v-col>
                      <app-primary-button @click="sendMfaCode">Send</app-primary-button>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <reset-password-dialog :routeName="routeName" />
  </v-container>
</template>

<style scoped>
#sign-in-button .v-icon {
  padding-right: 0.5em;
}
</style>

<script>
import { getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier } from "firebase/auth"
import { auth } from "../main.js"
import eventBus from "../framework/eventBus"
import ResetPasswordDialog from "../components/account/ResetPasswordDialog.vue"
import AppPrimaryButton from "../components/common/buttons/AppPrimaryButton.vue"
import AppSecondaryButton from "../components/common/buttons/AppSecondaryButton.vue"
import AppTextBox from "../components/common/form_fields/AppTextBox.vue"

export default {
  components: { ResetPasswordDialog, AppPrimaryButton, AppSecondaryButton, AppTextBox },
  data() {
    return {
      routeName: "login",
      valid: true,
      error: "",
      email: "",
      TwoFACodeRules: [v => !!v || "2FA Code required"],
      emailRules: [v => !!v || "Email required", v => /.+@.+\..+/.test(v) || "Email must be valid"],
      password: "",
      passwordRules: [v => !!v || "Password required"],
      loginInProgress: false,
      mfaCode: null,
      verificationId: null,
    }
  },
  computed: {
    forgotPasswordDisabled() {
      return this.loginInProgress
    },
  },
  methods: {
    async login() {
      this.error = ""
      let valid = this.$refs.form.validate()
      if (valid) {
        try {
          await auth.signInWithEmailAndPassword(this.email, this.password)
          // User has not enroled in 2FA Auth
          this.$router.push("/")
        } catch (error) {
          if (error.code == "auth/multi-factor-auth-required") {
            this.enable2FA(error)
            return
          }
          this.error = error
        }
      }
    },
    async enable2FA(error) {
      this.loginInProgress = true
      this.recaptchaDisplayed = true
      if (this.recaptchaVerifier == null) {
        this.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container-id", undefined, auth)
      }
      this.resolver = getMultiFactorResolver(auth, error)
      const phoneInfoOptions = {
        multiFactorHint: this.resolver.hints[0],
        session: this.resolver.session,
      }
      const phoneAuthProvider = new PhoneAuthProvider(auth)
      try {
        this.verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, this.recaptchaVerifier)
        this.smsSent = true
      } catch (error) {
        this.error = error
      }
    },
    async sendMfaCode() {
      this.error = ""
      let valid = this.$refs.form2.validate()
      if (valid) {
        try {
          const cred = PhoneAuthProvider.credential(this.verificationId, this.mfaCode)
          const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

          await this.resolver.resolveSignIn(multiFactorAssertion)
          this.$router.push("/")
        } catch (error) {
          if (error.code == "auth/missing-code" || error.code == "auth/invalid-verification-code") {
            this.error = "Invalid 2FA Code"
            return
          }
          this.error = error
        }
      }
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    forgotPassword() {
      eventBus.$emit(`${this.routeName}showResetPasswordDialog`)
    },
  },
}
</script>
