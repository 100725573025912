/* eslint-disable no-console */

import eventBus from "../framework/eventBus"

export function showInfoMessage(message) {
  eventBus.$emit("show-message", message)
}

export function showErrorMessage(message, error) {
  eventBus.$emit("show-error", message)
  if (error) {
    console.error(`${message} Error:${error}`)
  }
}

export function showWarningMessage(message) {
  eventBus.$emit("show-warning", message)
}
