/* eslint-disable no-console */

export const debug = (message, ...optionalParams) => {
  console.debug(message, optionalParams)
}

export const info = (message, ...optionalParams) => {
  console.info(message, optionalParams)
}

export const warn = (message, ...optionalParams) => {
  console.warn(message, optionalParams)
}

export const error = (message, ...optionalParams) => {
  console.error(message, optionalParams)
}
