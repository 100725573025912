export const permission = {
  viewUsers: "VIEW_USERS",
  addEditUsers: "ADD_EDIT_USERS",
  viewSecurityRoles: "VIEW_SECURITY_ROLES",
  addEditSecurityRoles: "ADD_EDIT_SECURITY_ROLES",
  viewWordTemplates: "VIEW_WORD_TEMPLATES",
  addEditWordTemplates: "ADD_EDIT_WORD_TEMPLATES",
  viewContacts: "VIEW_CONTACTS",
  addEditContacts: "ADD_EDIT_CONTACTS",
  viewTypeOfLaws: "VIEW_TYPE_OF_LAWS",
  addEditTypeOfLaw: "ADD_EDIT_TYPE_OF_LAWS",
  viewCompanySettings: "VIEW_COMPANY_SETTINGS",
  editCompanySettings: "EDIT_COMPANY_SETTINGS",
  viewClientAccount: "VIEW_CLIENT_ACCOUNT",
  addEditClientAccount: "ADD_EDIT_CLIENT_ACCOUNT",
  viewBranches: "VIEW_BRANCHES",
  addEditBranches: "ADD_EDIT_BRANCHES",
  viewMatters: "VIEW_MATTER",
  addEditMatters: "ADD_EDIT_MATTER",
  addEditMatterFee: "ADD_EDIT_MATTER_FEE",
  viewTimeEntry: "VIEW_TIME_ENTRY",
  addEditTimeEntry: "ADD_EDIT_TIME_ENTRY",
  viewTypeOfDisbursement: "VIEW_TYPE_OF_DISBURSEMENT",
  addEditTypeOfDisbursement: "ADD_EDIT_TYPE_OF_DISBURSEMENT",
  viewDisbursement: "VIEW_DISBURSEMENT",
  editDisbursementDate: "EDIT_DISBURSEMENT_DATE",
  viewBlockedUserForClientAccount: "VIEW_BLOCKED_USER_FOR_CLIENT_ACCOUNT",
  addEditBlockedUserForClientAccount: "ADD_EDIT_BLOCKED_USER_FOR_CLIENT_ACCOUNT",
  viewConflictSearch: "VIEW_CONFLICT_SEARCH",
  addEditConflictSearch: "ADD_EDIT_CONFLICT_SEARCH",
  viewClientAccountKnownConflict: "VIEW_CLIENT_ACCOUNT_KNOWN_CONFLICT",
  addEditClientAccountKnownConflict: "ADD_EDIT_CLIENT_ACCOUNT_KNOWN_CONFLICT",
  viewFlatFee: "VIEW_FLAT_FEE",
  addEditFlatFee: "ADD_EDIT_FLAT_FEE",
  viewQuickbooksSyncLog: "VIEW_QUICKBOOKS_SYNC_LOG",
  viewAuditedTransaction: "VIEW_AUDITED_TRANSACTION",
  addSoftDisbursement: "ADD_SOFT_DISBURSEMENT",
  editSoftDisbursement: "EDIT_SOFT_DISBURSEMENT",
  addHardDisbursement: "ADD_HARD_DISBURSEMENT",
  editHardDisbursement: "EDIT_HARD_DISBURSEMENT",
  addEditMyBill: "ADD_EDIT_MY_BILL",
  accountingSuperAdmin: "ACCOUNTING_SUPER_ADMIN",
  viewBillingTaxSettings: "VIEW_BILLING_TAX_SETTINGS",
  addEditBillingTaxSettings: "ADD_EDIT_BILLING_TAX_SETTINGS",
  trustManagementGeneral: "TRUST_MANAGEMENT_GENERAL",
  trustManagmentAdmin: "TRUST_MANAGEMENT_ADMIN",
  trustTransfer: "TRUST_TRANSFER",
  requestTrustRelease: "REQUEST_TRUST_RELEASE",
  approveDenyTrustRelease: "APPROVE_DENY_TRUST_RELEASE",
  trustReleaseCheque: "TRUST_RELEASE_CHEQUE",
  statementManagement: "STATEMENT_MANAGEMENT",
  viewStatement: "VIEW_STATEMENT",
  addEditCredit: "ADD_EDIT_CREDIT",
  requestGeneralCheque: "REQUEST_GENERAL_CHEQUE",
  releaseGeneralCheque: "RELEASE_GENERAL_CHEQUE",
  reportsAdmin: "REPORTS_ADMIN",
  viewReports: "VIEW_REPORTS",
  viewMatterListing: "VIEW_MATTER_LISTING",
  addEditSystemSettings: "ADD_EDIT_SYSTEM_SETTINGS",
  viewBillJournal: "VIEW_BILL_JOURNAL",
  viewMatterTransactionListing: "VIEW_MATTER_TRANSACTION_LISTING",
  addBulkDisbursement: "ADD_BULK_DISBURSEMENT",
  viewClientSummary: "VIEW_CLIENT_SUMMARY",
  viewBillingReport: "VIEW_BILLING_REPORT",
  viewTrustListing: "VIEW_TRUST_LISTING",
  viewTrustListingAccount: "VIEW_TRUST_LISTING_ACCOUNT",
  editBillFeeAllocation: "EDIT_BILL_FEE_ALLOCATION",
  viewOriginalBookOfEntryTrust: "VIEW_ORIGINAL_BOOK_OF_ENTRY_TRUST",
  viewOriginalBookOfEntryGeneral: "VIEW_ORIGINAL_BOOK_OF_ENTRY_GENERAL",
  viewSystemBalanceReport: "VIEW_SYSTEM_BALANCE_REPORT",
  viewAccountsReceivableReport: "VIEW_ACCOUNTS_RECEIVABLE_REPORT",
  disbursementTranfer: "DISBURSEMENT_TRANSFER",
  writeOff: "WRITE_OFF",
  viewEditBill: "VIEW_EDIT_BILL",
}
